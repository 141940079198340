import { useEffect, useState } from "react";
import {
  CloseOutlined,
  ExclamationCircleFilled,
  UpOutlined,
  FolderOutlined,
  LikeOutlined,
  LikeFilled,
  EllipsisOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Empty, Modal, Pagination, Spin, Tag } from "antd";
import { addOrUpdateProject } from "../../fakeApi";
import { useNavigate } from "react-router-dom";
import { requester } from "../../../../requester";
import { toast } from "react-toastify";

const items = [
  {
    label: "Delete",
    key: "0",
  },
];
const PublicProjects = () => {
  const [sharedProjects, setSharedProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default page size

  const navigate = useNavigate();

  const { confirm } = Modal;

  function fetchMySharedProjects(page = 0, size = 10) {
    setLoading(true);
    requester
      .get(`/myPubProj?page=${page}&size=${size}`)
      .then((response: any) => {
      
        setSharedProjects(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message ||
            "Something went wrong while fetching shared projects"
        );
        setLoading(false);
      });
  }

  useEffect(() => {
    // If no tags are selected, fetch all projects (normal pagination)
    fetchMySharedProjects(currentPage - 1, pageSize);
  }, [currentPage, pageSize]);

  const handleSharedProjectClick = (projectData) => {
    
    try {
      const data = JSON.parse(projectData.data);
      if (data.store) {
        addOrUpdateProject(data, data.tempProject.id);
        navigate(`/codeyai/diagram/${data.tempProject.id}`);
      } else if (data && !data.store && data.metaData) {
        addOrUpdateProject(data, data.id);
        navigate(`/codeyai/diagram/${data.id}`);
      } else {
        toast.warning("Something wrong with this project");
      }
    } catch (error) {
      toast.warning("Something wrong with this project");
    }
  };

  const handleIncreaseVoteClick = (projectId) => {
    setLoading(true);
    requester
      .get(`/increaseRank?projID=${encodeURIComponent(projectId)}`)
      .then((response: any) => {
        

        fetchMySharedProjects(currentPage - 1, pageSize);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message || "Something went wrong while voting up"
        );
        setLoading(false);
      });
  };

  const handleDeleteProject = (id) => {
    confirm({
      title: "delete my public project",
      icon: <ExclamationCircleFilled />,
      content: "Are you sure you want to delete this project?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        return requester
          .get(`/delMyPubProj?projID=${encodeURIComponent(id)}`)
          .then((response: any) => {
           
            fetchMySharedProjects(currentPage - 1, pageSize);
          })
          .catch((err) => {
            console.log(err);
            toast.error(
              err?.response?.data?.message ||
                "Something went wrong while voting up"
            );
          });
      },

      onCancel() {
        console.log("Cancel");
      },
    });
  };

  if (loading) {
    return (
      <div style={{ height: "60vh", display: "grid", placeItems: "center" }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      <div className="projects-wrapper">
        {sharedProjects?.length > 0 ? (
          sharedProjects?.map((project) => {
            const isVoted = project.voted;
            return (
              <div
                className="h-fit flex gap-6 pl-12 pr-10 py-5 border-b-[1px] relative"
                key={project.proj} // Use project.id as key
              >
                <div className="absolute top-8 right-12 cursor-pointer hover:scale-105">
                  {/* <CloseOutlined /> */}
                  <Dropdown
                    placement="bottomRight"
                    menu={{
                      items: [
                        {
                          label: (
                            <span
                              onClick={() => handleDeleteProject(project.proj)}
                              className="text-red-500"
                            >
                              Delete
                            </span>
                          ),
                          key: "0",
                        },
                      ],
                    }}
                    trigger={["click"]}
                  >
                    <span className="bg-gray-100 px-1 pt-[4px] rounded">
                      <EllipsisOutlined
                        style={{ fontSize: "1.3rem", fontWeight: "bold" }}
                      />
                    </span>
                  </Dropdown>
                </div>
                <div className="w-full flex flex-col gap-1">
                  <div className="flex items-center gap-3">
                    <FolderOutlined
                      style={{ fontSize: "20px", color: "red" }}
                    />
                    <p
                      className="text-blue-600 cursor-pointer hover:text-blue-400"
                      style={{ fontWeight: "normal", fontSize: 16 }}
                      onClick={() => handleSharedProjectClick(project)}
                    >
                      {project.title}
                    </p>
                  </div>
                  <p
                    className="text-sm first-letter:capitalize text-gray-600"
                    style={{ fontSize: "12px" }}
                  >
                    {project.description}
                  </p>
                  {project?.tags && (
                    <div className="flex flex-wrap gap-y-1 mt-2">
                      {project.tags.map((tag, idx) => (
                        <Tag key={"tag" + idx}>{tag}</Tag>
                      ))}
                    </div>
                  )}
                  <div className="flex items-center justify-between h-full gap-2 text-[0.9rem]">
                    {/* <span
                      className="text-blue-600 pr-1"
                      style={{ fontSize: "13px", textTransform: "capitalize" }}
                    >
                      {project.email}
                    </span> */}
                    <div>
                      <span>ranks: </span>
                      <span
                        className="font-bold text-gray-500"
                        // style={{ fontSize: "", textTransform: "capitalize" }}
                      >
                        {project.rank}
                      </span>
                    </div>

                    <div className="flex items-end gap-2">
                      <span
                        className="text-gray-500 pl-1"
                        style={{ fontSize: "13px" }}
                      >
                        published{" "}
                        {new Date(project.timeStamp).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          }
                        )}
                      </span>
                      <span
                        className="cursor-pointer text-[16px]"
                        onClick={() => {
                          if (!isVoted) {
                            handleIncreaseVoteClick(project.proj);
                          }
                        }}
                        title={isVoted ? "Voted" : "Vote"}
                      >
                        {isVoted ? (
                          <LikeFilled className="text-blue-500" />
                        ) : (
                          <LikeOutlined
                            className="hover:text-blue-500"
                            style={{ fontSize: "1.3rem" }}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="grid place-items-center h-[70vh]">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </div>
      {sharedProjects?.length > 8 && (
        <div className="pagination-btns mt-4">
          <Pagination
            total={sharedProjects?.length}
            pageSize={pageSize}
            current={currentPage}
            onChange={(page, size) => {
              setCurrentPage(page);
              setPageSize(size);
            }}
            showSizeChanger
            align="center"
          />
        </div>
      )}
    </div>
  );
};

export default PublicProjects;
