import Button from "../../components/Button";
import searchIcon from "../../../assets/searchIcon.svg";
import lines from "../../../assets/lines.svg";
import blocks from "../../../assets/blocks.svg";
import { useState, useEffect } from "react";
import RecentProjects from "./RecentProjects";
import { Upload, Spin, Dropdown, message } from "antd";
import { addOrUpdateProject, getAllProjects } from "../fakeApi";
import { useUserIdentity } from "../../../hooks/useUserIdentity";
import { PlusCircleOutlined } from "@ant-design/icons";
import { AddProjectModal } from "./AddProjectModal";
import { requester } from "../../../requester";
import { useRecoilState } from "recoil";
import { userDataAtom } from "../../../atoms/auth.atom";
import { toast } from "react-toastify";

interface endpoint {
  key: string;
  label: string;
  description: string;
  endpoint: string;
}

const ProjectsMain = () => {
  const [projects, setProJects] = useState([]);
  const [search, setSearch] = useState("");
  const [projectsView, setProjectsView] = useState("grid");
  const { canAccessDiagram } = useUserIdentity();
  const [isAddModalopen, setIsAddModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useRecoilState(userDataAtom);
  const [counter, setCounter] = useState(userData?.gen_code_counter);

  const endpoints: endpoint[] = [
    {
      key: "enhance",
      label: " Enhance Code",
      description:
        "This will improve the code and generate a unit test for all the classes.",
      endpoint: "/reversEng/enhance",
    },
    {
      key: "GenUnitTest",
      label: " Gen UnitTests",
      description:
        "This generate all unit test for all classes in the project.",
      endpoint: "/reversEng/unitTest",
    },
    {
      key: "javaDocs",
      label: " Java Docs ",
      description:
        "This will replace the code in each function with pseudocode that explains the logic of the code.",
      endpoint: "/reversEng/javaDocs",
    },
    {
      key: "jsonDocs",
      label: " Json Docs",
      description:
        "This will generate a JSON file that shows the structure of the project with pseudocode in each function.",
      endpoint: "/reversEng/jsonDocs",
    },
    {
      key: "BootToFlux",
      label: " Convert Springboot to Webflux [Beta version]",
      description:
        "This generate all unit test for all classes in the project.",
      endpoint: "/reversEng/bootToFlux",
    },
    {
      key: "FluxToBoot",
      label: " Convert Webflux to Springboot [Beta version]",
      description:
        "This generate all unit test for all classes in the project.",
      endpoint: "/reversEng/fluxToBoot",
    },
    {
      key: "Spring_vertx",
      label: " Convert Springboot to Vert.x [Beta version]",
      description: "Convert Springboot project to a Vert.x project.",
      endpoint: "/reversEng/springToVertx",
    },
    {
      key: "Vertx_spring",
      label: " Convert Vert.x to Springboot [Beta version]",
      description: "Convert Vert.x project to Springboot project",
      endpoint: "/reversEng/vertxToSpring",
    },
  ];

  const handleCounter = async () => {
    setLoading(true);
    await requester
      .get("/gencounter")
      .then((response: any) => {
        console.log("response counter", response);

        setLoading(false);
        setCounter(response?.data?.gen_code_counter);

        localStorage.setItem(
          "userData",
          JSON.stringify({
            ...userData,
            gen_code_counter: response?.data?.gen_code_counter,
          })
        );

        setUserData((prev) => {
          return {
            ...prev,
            gen_code_counter: response?.data?.gen_code_counter,
          };
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          <div style={{ textAlign: "center" }}>
            Something went wrong, please try again
          </div>
        );
      });
  };

  const getProjects = () => {
    if (canAccessDiagram) setProJects(getAllProjects()?.reverse());
  };

  const handleFileUpload = ({ file }, url) => {
    // return;
    if (!file) {
      console.error("No file selected");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    setLoading(true);
    // requester
    //   .post(url, formData)

    fetch(
      "https://codeyai-cont-app.proudocean-7b32e3d1.centralus.azurecontainerapps.io" +
        url,
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.getItem("userData")).token
          }`,
        },
      }
    )
      .then(async (response: any) => {
        if (response.ok) {
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "project.zip";
          link.click();
          window.URL.revokeObjectURL(url);

          getProjects();
          handleCounter();
        } else {
          console.error("Failed to upload file", response.statusText);
          message.error(`Error uploading file: ${response.statusText}`);
        }
      })
      .catch((error) => {
        message.error(`Error uploading file: ${error}`);
        console.error("Error uploading file:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getProjects();
  }, [canAccessDiagram]);

  const onChange = (options) => {
    var reader = new FileReader();
    reader.onload = onReaderLoad;
    reader.readAsText(options.file);
  };

  const onReaderLoad = (event) => {
    var projectData = JSON.parse(event.target.result);
    if (projectData.store) {
      addOrUpdateProject(projectData, projectData.tempProject.id);
    } else if (projectData && !projectData.store) {
      addOrUpdateProject(projectData, projectData.id);
    }
    getProjects();
  };

  const menuItems = endpoints.map((endpoint) => ({
    key: endpoint.key,
    label: (
      <Upload
        showUploadList={false}
        customRequest={(file) => handleFileUpload(file, endpoint.endpoint)}
      >
        <div>
          <strong style={{ color: "#007A", fontSize: "18px" }}>
            {endpoint.label}
          </strong>
          <p
            style={{
              margin: "0",
              fontSize: "14px",
              color: "black",
              maxWidth: "450px",
              wordWrap: "break-word",
              whiteSpace: "normal",
            }}
          >
            {endpoint.description}
          </p>
        </div>
      </Upload>
    ),
  }));

  const menuProps = {
    items: menuItems,
  };

  const getTextColor = (value) => {
    const counter = parseInt(value);
    if (counter === 0) {
      return "text-red-500";
    }
    return "text-green-300";
  };

  console.log("counter", counter);

  return (
    <>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 1000,
          }}
        >
          <Spin size="large" />
        </div>
      )}
      <main className="flex flex-col grow max-w-full w-[50%]">
        <div className="flex justify-between items-center gap-[24px] sm:gap-0 border-b">
          <p className="font-medium text-[20px] pl-8">My Projects</p>
          <div className="flex items-center gap-8 flex-col sm:flex-row py-2 pr-8 ">
            {canAccessDiagram && (
              <Dropdown
                menu={menuProps}
                trigger={["click"]}
                overlayStyle={{
                  width: "500px",
                }}
                disabled={counter == 0}
              >
                <Button
                  className={`btn h-10 element-center gap-[10px] rounded-lg py-[10px] px-5 ${
                    counter == 0 && "cursor-not-allowed"
                  }`}
                >
                  <span>Upload Project</span>{" "}
                  <span
                    className={`text-xs font-semibold ${getTextColor(counter)}`}
                  >
                    {counter} left
                  </span>
                </Button>
              </Dropdown>
            )}

            <Button
              onClick={() => setIsAddModalOpen(true)}
              className={`btn h-10 element-center gap-[10px] rounded-lg py-[10px] px-5`}
            >
              <span>
                <PlusCircleOutlined />
              </span>
              <span className="text-[10px] sm:text-[14px] font-medium">
                New Project
              </span>
            </Button>
            {canAccessDiagram && (
              <Upload showUploadList={false} customRequest={onChange}>
                <Button className="h-10 bg-[#EBF1FF] rounded-lg py-[10px] px-5 text-gray-600 font-medium text-[14px]">
                  Import Diagram
                </Button>
              </Upload>
            )}
          </div>
        </div>

        {canAccessDiagram && (
          <div className="flex flex-col lg:flex-row gap-4 lg:gap-0 items-center justify-between px-8 py-8">
            <div className="flex gap-4 border rounded-lg items-center px-4 py-[14px] w-full sm:w-[360px]">
              <span>
                <img src={searchIcon} alt="searchIcon" />
              </span>
              <input
                placeholder="Search"
                className="outline-none w-full max-w-full"
                onChange={(e) => setSearch(e.target?.value)}
              />
            </div>
            <div className="flex gap-10 items-center">
              <div className="flex gap-4 [&>_.active]:bg-[#F4F5F7]">
                <span
                  onClick={() => setProjectsView("grid")}
                  className={
                    projectsView === "grid"
                      ? "active rounded"
                      : "cursor-pointer"
                  }
                >
                  <img src={blocks} alt="blocks" />
                </span>
                <span
                  onClick={() => setProjectsView("list")}
                  className={
                    projectsView === "list"
                      ? "active rounded"
                      : "cursor-pointer"
                  }
                >
                  <img src={lines} alt="lines" />
                </span>
              </div>
            </div>
          </div>
        )}
        <RecentProjects
          getProjects={getProjects}
          projects={projects?.filter?.((project) =>
            search ? project?.metaData.name?.includes(search) : true
          )}
          setIsAddModalOpen={setIsAddModalOpen}
          projectsView={projectsView}
        />
      </main>
      <AddProjectModal isOpen={isAddModalopen} setIsOpen={setIsAddModalOpen} />
    </>
  );
};

export default ProjectsMain;
