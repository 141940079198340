import { requester } from "../../requester";
import dayjs from "dayjs";

export const singIn = (params) => {
  return new Promise((resolve, reject) => {
    requester
      .post("/singIn", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
};
export const resetPassword = (params) => {
  return new Promise((resolve, reject) => {
    requester
      .post("/resetPWD", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export const recoverPassword = (params) => {
  return new Promise((resolve, reject) => {
    requester
      .post("/recover", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
};

export const googleSignIn = (params) => {
  return new Promise((resolve, reject) => {
    requester
      .get("/google", {
        headers: {
          Authorization: `Bearer ${params.access_token}`,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
};

export const signUp = (params) => {
  return new Promise((resolve, reject) => {
    requester
      .post("/signup", {
        ...params,
        dob: dayjs(params.dob).format("MM/DD/YYYY"),
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => reject(err));
  });
};
