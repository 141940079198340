import { useGoogleLogin as useGoogleSign } from "@react-oauth/google";
import { toast } from "react-toastify";
import { googleSignIn } from "../api/auth";
import { useSetRecoilState } from "recoil";
import { userDataAtom } from "../atoms/auth.atom";
import { To, useNavigate } from "react-router-dom";
import { useState } from "react";

export const useGoogleLogin = () => {
  const setUserData = useSetRecoilState(userDataAtom);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean | null>(false); // Loading state
  const [error, setError] = useState<boolean | string | null>(false);

  const onGoogleLogin = useGoogleSign({
    onSuccess: async (tokenResponse) => {
      setLoading(true); // Start loading
      try {
        const response = await googleSignIn(tokenResponse);
        const userData = { ...response, msg: undefined };
        localStorage.setItem("userData", JSON.stringify(userData));
        setUserData(userData);
        // navigate("/codeyai/projects", { replace: true });
        // navigate(-1, { replace: true });
        navigate(-1 as any, { replace: true });
      } catch (error) {
        // toast.error("Failed to login");
        console.log("onError");

        setError("Failed to login");
      } finally {
        setLoading(false); // Stop loading
      }
    },
    onError: () => {

      // toast.error("Failed to login");
      setError("Failed to login");
      setLoading(false); // Ensure loading stops on error
    },
  });

  return {
    onGoogleLogin,
    setLoading,
    setError,
    loading,
    error,
  };
};
