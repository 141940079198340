import { Navigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userDataAtom } from "../atoms/auth.atom";

const ProtectedRoutes = ({ children }: { children: JSX.Element }) => {
  const [userData, setUserData] = useRecoilState(userDataAtom);
  return userData ? children : <Navigate to="/" replace />;
};

export default ProtectedRoutes;
