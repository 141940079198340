import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  FormInstance,
  Input,
  List,
  Modal,
  Radio,
  Select,
  Tag,
} from "antd";
// import { toast } from "react-toastify";
// import { requester } from "../../../../requester";
import { dependencies, dependenciesByCategory } from "./dependencies";
import { SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
const { Item } = Form;

type Props = {
  form: FormInstance<any>;
  recentSpringWebfluxMetaData: any;
  setRecentProjectsMetaData: any;
};
// const { Search } = Input;

export const SpringWebfluxIntializer: React.FC<Props> = ({
  form,
  recentSpringWebfluxMetaData,
  setRecentProjectsMetaData,
}) => {
  const tempProject = useSelector((state: any) => state.tempProject);

  const [dependenciesGetValue, setdependenciesGetValue] = useState(
    dependenciesByCategory
  );

  const [openDependenciesModal, setOpenDependenciesModal] = useState(false);
  const [SelectedDependencies, setSelectedDependencies] = useState([]);
 

  useEffect(() => {
    setSelectedDependencies(() => {
      const projectDep = tempProject?.metaData?.dependencies;
      if (projectDep && Array.isArray(dependenciesByCategory)) {
        const showedDependencies = dependenciesByCategory
          .flatMap((category) =>
            Array.isArray(category.items)
              ? category.items.map((item) => ({
                  categoryName: category.category,
                  value: projectDep?.includes(item.value) ? item.value : null,
                  label: item.label,
                  description: item.description,
                }))
              : []
          )
          ?.filter((dep) => dep.value !== null);

        return showedDependencies;
      }
      return []; // Return an empty array if dependenciesByCategory or projectDep is not valid
    });
  }, [tempProject?.metaData?.dependencies, dependenciesByCategory]);

  form.setFieldValue(
    "dependencies",
    SelectedDependencies.map((item) => {
      return item.value;
    })
  );

  const handelSearchDependencies = (value: string) => {
    const filteredResults = dependenciesByCategory
      .map((category) => {
        const filtered = category?.items?.filter((item) =>
          item.label?.toLowerCase().includes(value?.toLowerCase())
        );
        return filtered?.length ? { ...category, items: filtered } : null;
      })
      .filter(Boolean);
    setdependenciesGetValue(filteredResults);
  };
  const hundelSelectedDependencies = (selectedItem: any, categoryName) => {
    const isSelected = SelectedDependencies.some(
      (item) => item.value === selectedItem.value
    );
    if (isSelected) {
      setSelectedDependencies(
        SelectedDependencies.filter((item) => item.value !== selectedItem.value)
      );
    } else {
      setSelectedDependencies([
        ...SelectedDependencies,
        { ...selectedItem, categoryName },
      ]);
    }
  };

  const handleRecentSpringClick = (project: any) => {
    form.setFieldsValue(project?.metaData);
    setSelectedDependencies(() => {
      const projectDep = project?.metaData?.dependencies;
      const showedDependencies = dependenciesByCategory
        .flatMap((category) =>
          category.items.map((item) => ({
            categoryName: category.category,
            value: projectDep?.includes(item.value) ? item.value : null,
            label: item.label,
            description: item.description,
          }))
        )
        .filter((dep) => dep.value !== null);

      return showedDependencies;
    });
  };
  const handleRemoveRecentClick = (project: any) => {
    const recentProjectMetaData = JSON.parse(
      localStorage.getItem("recentProjectMetaData")
    );

    const updatedMetaData = recentProjectMetaData.filter(
      (item: any) => item.id !== project.id
    );

    localStorage.setItem(
      "recentProjectMetaData",
      JSON.stringify(updatedMetaData)
    );
    setRecentProjectsMetaData(
      JSON.parse(localStorage.getItem("recentProjectMetaData"))
    );
  };

  return (
    <div>
      <div className="max-w-100 flex flex-col lg:gap-10 gap-8 lg:flex-row border-b-[1px] border-neutral-200">
        <div className="flex lg:flex-col lg:gap-10 gap-10 flex-wrap justify-between">
          <div className="flex lg:justify-between gap-16 lg:w-full">
            <Item
              rules={[{ required: true, message: "This field is required" }]}
              required={false}
              name="type"
              label="Project"
            >
              <Radio.Group value="Maven" className="grid grid-cols-1 gap-4">
                <Radio disabled>Gradel - Groovy</Radio>
                <Radio disabled>Gradel - Kotlin</Radio>
                <Radio value="maven-project" checked>
                  Maven
                </Radio>
              </Radio.Group>
            </Item>
            <Item
              rules={[{ required: true, message: "This field is required" }]}
              required={false}
              name="language"
              label="Language"
            >
              <Radio.Group className="grid grid-cols-1 gap-4">
                <Radio value="java">Java</Radio>
                <Radio disabled>Kotlin</Radio>
                <Radio disabled>Maven</Radio>
              </Radio.Group>
            </Item>
          </div>
          <div className="flex-2">
            <Item
              rules={[{ required: true, message: "This field is required" }]}
              required={false}
              name="bootVersion"
              label="Spring Boot"
            >
              <Radio.Group className="grid grid-cols-2 gap-4">
                <Radio value="3.4.2-SNAPSHOT">3.4.2 (SNAPSHOT)</Radio>
                <Radio value="3.4.1">3.4.1</Radio>
                <Radio value="3.3.8-SNAPSHOT">3.3.8 (SNAPSHOT)</Radio>
                <Radio value="3.3.7">3.3.7</Radio>
              </Radio.Group>
            </Item>
          </div>
        </div>
        <div
          className="hidden sm:block bg-neutral-200"
          style={{ width: "0.7px" }}
        />
        <div className="flex flex-col flex-1">
          {/* Project Metadata */}
          <div className="mb-4 font-bold">Project Metadata</div>
          {/* Start container two boxs From */}
          <div className="flex gap-6 flex-wrap">
            <div className="flex-1">
              <Item
                rules={[{ required: true, message: "This field is required" }]}
                required={false}
                name="groupId"
                label="Group"
              >
                <Input
                  onChange={(e) => {
                    form.setFieldValue(
                      "packageName",
                      e.target.value +
                        "." +
                        (form.getFieldValue("artifactId") || "")
                    );
                  }}
                />
              </Item>
              <Item
                rules={[{ required: true, message: "This field is required" }]}
                required={false}
                name="artifactId"
                label="Artifact"
              >
                <Input
                  onChange={(e) => {
                    form.setFieldValue(
                      "packageName",
                      (form.getFieldValue("groupId") || "") +
                        "." +
                        e.target.value
                    );
                    form.setFieldValue("name", e.target.value);
                  }}
                />
              </Item>
              <Item
                rules={[{ required: true, message: "This field is required" }]}
                required={false}
                name="name"
                label="Name"
              >
                <Input disabled />
              </Item>

              <Item
                rules={[{ required: true, message: "This field is required" }]}
                required={false}
                name="description"
                label="Description"
              >
                <Input />
              </Item>
            </div>
            <div className="flex-1">
              <Item
                rules={[{ required: true, message: "This field is required" }]}
                required={false}
                name="packageName"
                label="Package name"
              >
                <Input disabled />
              </Item>
              <Item
                rules={[{ required: true, message: "This field is required" }]}
                required={false}
                name="packaging"
                label="Packaging"
              >
                <Radio.Group>
                  <Radio value="jar">Jar</Radio>
                  <Radio value="war">War</Radio>
                </Radio.Group>
              </Item>
              <Item
                rules={[{ required: true, message: "This field is required" }]}
                required={false}
                name="javaVersion"
                label="Java"
              >
                <Radio.Group>
                  <Radio value="23">23</Radio>
                  <Radio value="21">21</Radio>
                  <Radio value="17">17</Radio>
                </Radio.Group>
              </Item>
            </div>
            <div
              className="hidden sm:block bg-neutral-200"
              style={{ width: "0.7px" }}
            />
            <Item name="dependencies" className="flex-1">
              {/* dependencies */}
              <div>
                <div className="flex justify-between items-center">
                  <p className="text-[1rem] font-bold">Dependencies</p>
                  <Button
                    type="primary"
                    onClick={() => setOpenDependenciesModal(true)}
                  >
                    Add Dependencies
                  </Button>
                </div>
                <Modal
                  title="Dependencies"
                  open={openDependenciesModal}
                  onOk={() => setOpenDependenciesModal(false)}
                  footer={null}
                  onCancel={() => setOpenDependenciesModal(false)}
                  style={{
                    marginTop: "-3rem",
                    // maxHeight: "20rem",
                    // height: "50rem",
                    // overflow: "hidden",
                  }}
                  width={1000}
                  // height={1000}
                >
                  <Input
                    placeholder="Search dependencies"
                    allowClear
                    onChange={(e) =>
                      handelSearchDependencies(e.target.value.trim())
                    }
                    suffix={
                      <SearchOutlined
                        style={{
                          pointerEvents: "none", // Disable pointer events to make it non-clickable
                        }}
                      />
                    }
                  />

                  <List
                    style={{
                      marginTop: "1rem",
                      maxHeight: "70vh",
                      overflow: "auto",
                      cursor: "pointer",
                    }}
                    bordered
                    loadMore
                  >
                    {dependenciesGetValue.map((category) => (
                      <div key={category.category}>
                        <List.Item
                          style={{
                            // fontWeight: "bold",

                            position: "sticky",
                            top: "0",
                            background: "white",
                            fontSize: "1rem",
                            cursor: "default",
                            borderTop: "1px solid #dedede",
                          }}
                        >
                          <span className="bg-sky-700 px-[10px]  ml-[-.5rem] text-[#fff] font-semibold">
                            {category.category}
                          </span>
                        </List.Item>
                        {category.items.map((item) => (
                          <List.Item
                            key={item.value}
                            onClick={() =>
                              hundelSelectedDependencies(
                                item,
                                category.category
                              )
                            }
                            className={` ${
                              SelectedDependencies.some(
                                (selected) => selected.value === item.value
                              ) && "bg-sky-50 hover:bg-[#e2f1fbbd]"
                            } hover:bg-sky-50`}
                            style={{ margin: "0.1rem 0" }}
                          >
                            <div
                              className={`${
                                SelectedDependencies.some(
                                  (selected) => selected.value === item.value
                                ) && "text-sky-700 "
                              }`}
                            >
                              <h2 className="font-bold mb-1">{item.label}</h2>

                              <p
                                className={`${
                                  SelectedDependencies.some(
                                    (selected) => selected.value === item.value
                                  )
                                    ? "text-sky-700 "
                                    : "text-[#616161]"
                                }`}
                              >
                                {" "}
                                {item.description}
                              </p>
                            </div>
                          </List.Item>
                        ))}
                      </div>
                    ))}
                  </List>
                </Modal>
                <div className="flex flex-col gap-[0.3rem] border-solid  p-2 mt-2 max-h-[18rem] overflow-y-auto">
                  {SelectedDependencies?.map((dependency) => {
                    return (
                      <Tag
                        closable
                        className="flex justify-between py-[0.3rem] "
                        onClose={(e) => {
                          e.preventDefault();
                          hundelSelectedDependencies(dependency, null);
                        }}
                      >
                        <div className="max-h-fit">
                          <p className="flex gap-1 mb-1 flex-wrap">
                            <span className="font-bold">
                              {dependency.label}
                            </span>
                            <span className="bg-sky-700 px-[5px] font-semibold text-white">
                              {dependency.categoryName}
                            </span>
                          </p>
                          <p className="text-[0.75rem] text-gray-600 whitespace-normal line-clamp-2 leading-tight">
                            {dependency.description}
                          </p>
                        </div>
                      </Tag>
                    );
                  })}
                </div>
              </div>
            </Item>
          </div>
        </div>
      </div>
      {/* End container two boxs From*/}
      {recentSpringWebfluxMetaData?.length > 0 && (
        <div className="w-full py-2">
          <h2 className="text-[1rem] font-bold">
            Recent spring webflux projects metaData
          </h2>
          <div className="flex gap-2 my-3 flex-wrap">
            {recentSpringWebfluxMetaData?.map((project) => {
              return (
                <Card
                  key={project.id}
                  title={project.metaData.name}
                  extra={
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveRecentClick(project);
                      }}
                      className="hover:text-red-500 transition-colors"
                    >
                      <DeleteOutlined style={{ fontSize: "1.1rem" }} />
                    </span>
                  }
                  style={{ width: 300 }}
                  onClick={() => handleRecentSpringClick(project)}
                  className="cursor-pointer hover:scale-[101%] "
                >
                  <div className="mt-[-1rem]">
                    <p className="">
                      <span className="text-[0.9rem] font-medium">
                        Description:
                      </span>
                      <span className="mx-1 text-blue-900">
                        {project.metaData.description}
                      </span>
                    </p>
                    <p className="">
                      <span className="text-[0.9rem] font-medium">
                        Boot version:
                      </span>
                      <span className="mx-1 text-blue-900">
                        {project.metaData.bootVersion}
                      </span>
                    </p>
                    <p className="">
                      <span className="text-[0.9rem] font-medium">
                        Java version:
                      </span>
                      <span className="mx-1 text-blue-900">
                        {project.metaData.javaVersion}
                      </span>
                    </p>
                    <p className="">
                      <span className="text-[0.9rem] font-medium">
                        Packaging:
                      </span>
                      <span className="mx-1 text-blue-900">
                        {project.metaData.packaging}
                      </span>
                    </p>
                    <ul className="">
                      <li className="text-[0.9rem] font-medium">
                        Dependencies:
                      </li>
                      {project.metaData.dependencies.length > 0 ? (
                        project.metaData.dependencies?.map(
                          (dependency, index) => {
                            return (
                              <li className="text-blue-900" key={index}>
                                {dependency}
                              </li>
                            );
                          }
                        )
                      ) : (
                        <li className="text-red-900">No dependencies</li>
                      )}
                    </ul>
                  </div>
                </Card>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
