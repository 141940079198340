import { Navigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userDataAtom } from "../atoms/auth.atom";

const AuthorizedRoutes = ({ children }: { children: JSX.Element }) => {
  const [userData, setUserData] = useRecoilState(userDataAtom);
  return userData ? <Navigate to="/" replace /> : children;
};

export default AuthorizedRoutes;
