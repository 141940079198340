import { Card, Form, FormInstance, Input, Radio } from "antd";
import React from "react";
import { DeleteOutlined } from "@ant-design/icons";

const { Item } = Form;

type Props = {
  form: FormInstance<any>;
  recentVertxMetaData: any;
  setRecentProjectsMetaData: any;
};
export const JavaVertxIntializer: React.FC<Props> = ({
  form,
  recentVertxMetaData,
  setRecentProjectsMetaData,
}) => {
  

  const handleRecentMavenClick = (project: any) => {
 

    form.setFieldsValue(project.metaData);
  };
  const handleRemoveRecentClick = (project: any) => {
    const recentProjectMetaData = JSON.parse(
      localStorage.getItem("recentProjectMetaData")
    );

    const updatedMetaData = recentProjectMetaData.filter(
      (item: any) => item.id !== project.id
    );

    localStorage.setItem(
      "recentProjectMetaData",
      JSON.stringify(updatedMetaData)
    );
    setRecentProjectsMetaData(
      JSON.parse(localStorage.getItem("recentProjectMetaData"))
    );
  };

  return (
    <>
      <div className="max-w-100 flex flex-col lg:flex-row justify-between gap-10 border-b-[1px] border-neutral-200">
        <div className="flex gap-10 lg:gap-24 sm:gap-16">
          <Item
            rules={[{ required: true, message: "This field is required" }]}
            required={false}
            name="type"
            label="Project"
          >
            <Radio.Group value="Maven" className="grid grid-cols-1 gap-4">
              <Radio disabled>Gradel - Groovy</Radio>
              <Radio disabled>Gradel - Kotlin</Radio>
              <Radio value="maven-project" checked>
                Maven
              </Radio>
            </Radio.Group>
          </Item>
          <Item
            rules={[{ required: true, message: "This field is required" }]}
            required={false}
            name="language"
            label="Language"
          >
            <Radio.Group className="grid grid-cols-1 gap-4">
              <Radio value="java">Java</Radio>
              <Radio disabled>Kotlin</Radio>
              <Radio disabled>Maven</Radio>
            </Radio.Group>
          </Item>
        </div>
        <div
          className="hidden sm:block bg-neutral-200"
          style={{ width: "0.7px" }}
        />
        <div className="flex flex-col flex-1">
          <div className="mb-4 font-bold"> Project Metadata</div>
          <div className="flex justify-between gap-6 flex-1">
            <div className="flex-1">
              <Item
                rules={[{ required: true, message: "This field is required" }]}
                required={false}
                name="groupId"
                label="Group"
              >
                <Input
                  onChange={(e) => {
                    form.setFieldValue(
                      "packageName",
                      e.target.value +
                        "." +
                        (form.getFieldValue("artifactId") || "")
                    );
                  }}
                />
              </Item>
              <Item
                rules={[{ required: true, message: "This field is required" }]}
                required={false}
                name="artifactId"
                label="Artifact"
              >
                <Input
                  onChange={(e) => {
                    form.setFieldValue(
                      "packageName",
                      (form.getFieldValue("groupId") || "") +
                        "." +
                        e.target.value
                    );
                    form.setFieldValue("name", e.target.value);
                  }}
                />
              </Item>
              <Item
                rules={[{ required: true, message: "This field is required" }]}
                required={false}
                name="name"
                label="Name"
              >
                <Input disabled />
              </Item>

              <Item
                rules={[{ required: true, message: "This field is required" }]}
                required={false}
                name="description"
                label="Description"
              >
                <Input />
              </Item>
            </div>
            <div className="flex-1">
              <Item
                rules={[{ required: true, message: "This field is required" }]}
                required={false}
                name="packageName"
                label="Package name"
              >
                <Input disabled />
              </Item>
              <Item
                rules={[{ required: true, message: "This field is required" }]}
                required={false}
                name="packaging"
                label="Packaging"
              >
                <Radio.Group>
                  <Radio value="jar">Jar</Radio>
                  <Radio value="war">War</Radio>
                </Radio.Group>
              </Item>
              <Item
                rules={[{ required: true, message: "This field is required" }]}
                required={false}
                name="javaVersion"
                label="Java"
              >
                <Radio.Group>
                  <Radio value="23">23</Radio>
                  <Radio value="21">21</Radio>
                  <Radio value="17">17</Radio>
                </Radio.Group>
              </Item>
            </div>
          </div>
        </div>
      </div>
      {recentVertxMetaData?.length > 0 && (
        <div className="w-full py-2">
          <h2 className="text-[1rem] font-bold">
            Recent java vertx projects metaData
          </h2>
          <div className="flex gap-2 my-3 flex-wrap">
            {recentVertxMetaData?.map((project) => {
              return (
                <Card
                  key={project.id}
                  title={project.metaData.name}
                  extra={
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveRecentClick(project);
                      }}
                      className="hover:text-red-500 transition-colors"
                    >
                      <DeleteOutlined style={{ fontSize: "1.1rem" }} />
                    </span>
                  }
                  style={{ width: 300 }}
                  onClick={() => handleRecentMavenClick(project)}
                  className="cursor-pointer hover:scale-[101%] "
                >
                  <div className="mt-[-1rem]">
                    <p className="">
                      <span className="text-[0.9rem] font-medium">
                        Description:
                      </span>
                      <span className="mx-1 text-blue-900">
                        {project.metaData.description}
                      </span>
                    </p>
                    <p className="">
                      <span className="text-[0.9rem] font-medium">
                        Boot version:
                      </span>
                      <span className="mx-1 text-blue-900">
                        {project.metaData.bootVersion}
                      </span>
                    </p>
                    <p className="">
                      <span className="text-[0.9rem] font-medium">
                        Java version:
                      </span>
                      <span className="mx-1 text-blue-900">
                        {project.metaData.javaVersion}
                      </span>
                    </p>
                    <p className="">
                      <span className="text-[0.9rem] font-medium">
                        Packaging:
                      </span>
                      <span className="mx-1 text-blue-900">
                        {project.metaData.packaging}
                      </span>
                    </p>
                  </div>
                </Card>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
