import { useEffect, useState } from "react";
import {
  LikeOutlined,
  UpOutlined,
  FolderOutlined,
  LikeFilled,
} from "@ant-design/icons";
import { Button, Empty, Pagination, Spin, Tag } from "antd";
import { addOrUpdateProject } from "../../fakeApi";
import { useNavigate } from "react-router-dom";
import { requester } from "../../../../requester";
import { toast } from "react-toastify";

const PublicProjects = ({ tagsSearchClicked, setTagsSearchClicked }) => {
  const [sharedProjects, setSharedProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default page size

  const navigate = useNavigate();

  function fetchSharedProjects(page = 0, size = 10) {
    setLoading(true);
    requester
      .get(`/getAllProjects?page=${page}&size=${size}`)
      .then((response: any) => {
       
        setSharedProjects(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message ||
            "Something went wrong while fetching shared projects"
        );
        setLoading(false);
      });
  }

  function fetchSharedProjectsByTags(tags, page = 0, size = 10) {
    setLoading(true);
    requester
      .post(`/getProjByTags?page=${page}&size=${size}`, tags) // Remove JSON.stringify
      .then((response: any) => {
        setSharedProjects(response.data);
        setLoading(false);
    
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          <div style={{ textAlign: "center" }}>
            Something went wrong, please try again
          </div>
        );
        console.log("err", err);
      });
  }

  useEffect(() => {
    if (tagsSearchClicked.value.length > 0) {
      // If there are tags, always fetch projects by tags, regardless of pagination
      fetchSharedProjectsByTags(
        tagsSearchClicked.value,
        currentPage - 1,
        pageSize
      );
    } else {
      // If no tags are selected, fetch all projects (normal pagination)
      fetchSharedProjects(currentPage - 1, pageSize);
    }

    // Reset the clicked state after processing
    setTagsSearchClicked((prev) => ({ ...prev, clicked: false }));
  }, [tagsSearchClicked.value, currentPage, pageSize]);

  const handleSharedProjectClick = (projectData) => {
   

    try {
      const data = JSON.parse(projectData.data);
      if (data.store) {
        addOrUpdateProject(data, data.tempProject.id);
        navigate(`/codeyai/diagram/${data.tempProject.id}`);
      } else if (data && !data.store && data.metaData) {
        addOrUpdateProject(data, data.id);
        navigate(`/codeyai/diagram/${data.id}`);
      } else {
        toast.warning("Something wrong with this project");
      }
    } catch (error) {
      toast.warning("Something wrong with this project");
    }
  };

  const handleIncreaseVoteClick = (projectId) => {
    setLoading(true);
    requester
      .get(`/increaseRank?projID=${encodeURIComponent(projectId)}`)
      .then((response: any) => {
        
        if (tagsSearchClicked.clicked) {
          fetchSharedProjectsByTags(
            tagsSearchClicked.value,
            currentPage - 1,
            pageSize
          );
        } else {
          fetchSharedProjects(currentPage - 1, pageSize);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err?.response?.data?.message || "Something went wrong while voting up"
        );
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div style={{ height: "60vh", display: "grid", placeItems: "center" }}>
        <Spin size="large" />
      </div>
    );
  }
  const estimatedTotal = sharedProjects.length
    ? currentPage * pageSize + sharedProjects.length
    : currentPage * pageSize;

  return (
    <div>
      <div className="projects-wrapper">
        {sharedProjects?.length > 0 ? (
          sharedProjects?.map((project) => {
           

            const isVoted = project.voted;
            return (
              <div
                className="h-fit flex gap-6 pl-12 pr-10 py-5 border-b-[1px]"
                key={project.proj} // Use project.id as key
              >
                {/* <div className="flex flex-col items-center gap-3 justify-center h-full mt-[0.5rem]">
                  <Button
                    title={isVoted ? "Voted" : "Vote"}
                    type={isVoted ? "primary" : "default"}
                    shape="circle"
                    style={{ minWidth: "26px", width: "26px", height: "26px" }}
                    disabled={isVoted}
                    icon={
                      <UpOutlined
                        style={{
                          color: isVoted ? "white" : "blue",
                          fontSize: "11px",
                        }}
                      />
                    }
                    onClick={() => handleIncreaseVoteClick(project.id)}
                  />
                  <p className="font-bold text-[13px]">{project.rank}</p>
                </div> */}
                <div className="w-full flex flex-col gap-1">
                  <div className="flex items-center gap-3">
                    <FolderOutlined
                      style={{ fontSize: "20px", color: "red" }}
                    />
                    <p
                      className="text-blue-600 cursor-pointer hover:text-blue-400"
                      style={{ fontWeight: "normal", fontSize: 16 }}
                      onClick={() => handleSharedProjectClick(project)}
                    >
                      {project.title}{" "}
                    </p>
                  </div>
                  <p
                    className="text-sm first-letter:capitalize text-gray-600"
                    style={{ fontSize: "12px" }}
                  >
                    {project.description}
                  </p>
                  {project?.tags && (
                    <div className="flex flex-wrap gap-y-1 mt-2">
                      {project.tags.map((tag, idx) => (
                        <Tag key={"tag" + idx}>{tag}</Tag>
                      ))}
                    </div>
                  )}
                  <div className="flex items-end justify-end h-full gap-2">
                    {/* <span
                      className="text-blue-600 pr-1"
                      style={{ fontSize: "13px", textTransform: "capitalize" }}
                    >
                      {project.email}
                    </span> */}
                    <span
                      className="text-[0.75rem] text-blue-500"
                      // style={{ fontSize: "", textTransform: "capitalize" }}
                    >
                      {project.rank}
                    </span>
                    <span
                      className="text-gray-500 pl-1"
                      style={{ fontSize: "13px" }}
                    >
                      published{" "}
                      {new Date(project.timeStamp).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </span>
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        if (!isVoted) {
                          handleIncreaseVoteClick(project.proj);
                        }
                      }}
                      title={isVoted ? "Voted" : "Vote"}
                    >
                      {isVoted ? (
                        <LikeFilled
                          className="text-blue-500"
                          style={{ fontSize: "1.3rem" }}
                        />
                      ) : (
                        <LikeOutlined
                          className="hover:text-blue-500"
                          style={{ fontSize: "1.3rem" }}
                        />
                      )}
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="grid place-items-center h-[70vh]">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        )}
      </div>
      {/* {sharedProjects?.length > 0 && ( */}
      <div className="pagination-btns mt-4">
        <Pagination
          total={estimatedTotal}
          pageSize={pageSize}
          current={currentPage}
          onChange={(page, size) => {
            setCurrentPage(page);
            setPageSize(size);
          }}
          showSizeChanger
          align="center"
        />
      </div>
      {/* )} */}
    </div>
  );
};

export default PublicProjects;
